import React from "react";
import PropTypes from "prop-types";

function SelfPortrait({ alt, className, role, title }) {
  return (
    <svg
      alt={alt}
      className={className}
      height="662"
      role={role}
      title={title}
      viewBox="0 0 972 662"
      width="972"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="a">
        <stop offset="0" stop-color="#808080" stop-opacity=".25" />
        <stop offset=".54" stop-color="#808080" stop-opacity=".12" />
        <stop offset="1" stop-color="#808080" stop-opacity=".1" />
      </linearGradient>
      <linearGradient
        id="b"
        x1=".015%"
        x2="100.034%"
        y1="50.011%"
        y2="50.011%"
      />
      <linearGradient id="c" x1="50.024%" x2="50.024%" y1="99.989%" y2="0%" />
      <linearGradient
        id="d"
        x1="50.024%"
        x2="50.024%"
        y1="99.992%"
        y2=".002%"
      />
      <linearGradient id="e" x1="50%" x2="50%" y1="100.004%" y2="0%" />
      <g fill="none" fill-rule="evenodd" transform="translate(0 -1)">
        <path
          d="m184.72 486.11c2.353595-9.465067-1.362944-19.402336-9.35-25-2.06-1.39-4.36-2.45-6.26-4.05-3.17-2.67-5-6.6-6.28-10.53-3.14-9.42-4.13-20-9.63-28.51-5-7.75-14.76-10-23.41-10.79-3.15-.28-6.46-.43-9.34.88-4.82 2.18-7.12 7.87-7.34 13.16s1.16 10.5 1.5 15.78-.6 11.09-4.49 14.67-9.47 4-14.64 4.72c-6.768611.939459-13.4189147 2.591979-19.84 4.93-5.39 2-11.4 5.51-11.65 11.23-.21 4.68 3.57 8.42 6.41 12.15 5.31 7 8 16.16 6.16 24.73s-8 16.21-9.07 25.05c-.4047972 2.2454-.0934534 4.561238.89 6.62.8612215 1.397475 1.9975687 2.605269 3.34 3.55 3.33 2.57 7 4.64 10.2 7.43 6.57 5.82 10.13 14.27 12.87 22.61.26.8.5 1.62.75 2.42h-6.46c-13.1418741.070895-23.7825775 10.698225-23.87 23.84.0602211 13.169058 10.7209424 23.829779 23.89 23.89h64.5c13.169058-.060221 23.829779-10.720942 23.89-23.89-.060221-13.169058-10.720942-23.829779-23.89-23.89h-17.68c4.18-5.6 8.55-10.86 15-13s14.26-.89 19.32-5.39c2.379578-2.334771 3.922623-5.389227 4.39-8.69 1.070025-5.881636.070021-11.952335-2.83-17.18-2-3.63-5-6.92-5.65-11-.55-3.51.72-7.07 2.44-10.19 4.88-8.87 13.51-15.76 16.13-25.55z"
          fill="url(#b)"
          fill-rule="nonzero"
          opacity=".7"
        />
        <path
          d="m129.56 412.42c-3-.27-6.15-.41-8.89.84-4.59 2.08-6.78 7.5-7 12.53s1.11 10 1.43 15-.57 10.56-4.28 14-9 3.81-13.94 4.49c-6.4454394.903079-12.7773123 2.485209-18.89 4.72-5.13 1.87-10.85 5.24-11.09 10.7-.2 4.46 3.4 8 6.11 11.57 5.06 6.64 7.58 15.39 5.86 23.55s-7.58 15.44-8.64 23.86c-.3838877 2.137186-.0865518 4.340969.85 6.3.8228816 1.331518 1.9082616 2.481545 3.19 3.38 3.17 2.45 6.71 4.42 9.71 7.08 6.26 5.54 9.64 13.59 12.26 21.53 2.5598149 7.805388 4.564809 15.781925 6 23.87 5.39-3.47 12.36-3.42 18.11-6.24 12.25-6 16.37-23.4 29.3-27.73 6.12-2 13.58-.85 18.4-5.13 2.265232-2.22172 3.734456-5.128534 4.18-8.27 1.017015-5.601495.062963-11.38234-2.7-16.36-1.93-3.46-4.77-6.59-5.38-10.51-.53-3.35.69-6.73 2.32-9.7 4.65-8.45 12.87-15 15.38-24.33 2.241884-9.013442-1.295541-18.477048-8.9-23.81-2.067778-1.178131-4.070673-2.46666-6-3.86-3-2.54-4.73-6.29-6-10-3-9-3.93-19-9.17-27.15-4.7-7.44-13.98-9.59-22.22-10.33z"
          fill="#7ddba3"
          fill-rule="nonzero"
        />
        <path
          d="m129.47 412.73s11.37 152.41-18.2 178.57"
          opacity=".6"
          stroke="#535461"
        />
        <rect
          fill="currentColor"
          fill-rule="nonzero"
          height="45.5"
          rx="15.2"
          width="106.92"
          x="68.05"
          y="574.24"
        />
        <path
          d="m.92.87s29.08-3.39 64.59 18.21m-.57 18.19s16.16-26.73 50.86-31.84m-112.08 57.43s40.31-6.83 58.83 4.55m-2.26 15.59s21.1-9.57 44.84-1.8"
          opacity=".6"
          stroke="#535461"
          transform="translate(66 471)"
        />
        <g fill-rule="nonzero">
          <path
            d="m895.49 545.18v-16.4h-82v75.82c0 8.836556 7.163444 16 16 16h50c8.836556 0 16-7.163444 16-16v-16.8c7.61333.000001 14.648337-4.061663 18.455002-10.655 3.806666-6.593337 3.806666-14.716663 0-21.31-3.806665-6.593338-10.841672-10.655-18.455002-10.655zm0 34.43v-26.23c4.771887-.131779 9.238785 2.338704 11.663583 6.450713 2.424797 4.11201 2.424797 9.216564 0 13.328574-2.424798 4.112009-6.891696 6.582492-11.663583 6.450713z"
            fill="url(#c)"
            opacity=".7"
          />
          <path
            d="m892.86 547.74c-10.747461 0-19.46 8.712539-19.46 19.46s8.712539 19.46 19.46 19.46 19.46-8.712539 19.46-19.46c0-5.161113-2.050244-10.11084-5.699702-13.760298s-8.599185-5.699702-13.760298-5.699702zm0 31.43c-6.627417 0-12-5.372583-12-12s5.372583-12 12-12 12 5.372583 12 12c.002654 3.18433-1.260457 6.239147-3.511181 8.491747-2.250724 2.252599-5.304488 3.518254-8.488819 3.518253z"
            fill="currentColor"
          />
          <path
            d="m892.86 547.74c-10.747461 0-19.46 8.712539-19.46 19.46s8.712539 19.46 19.46 19.46 19.46-8.712539 19.46-19.46c0-5.161113-2.050244-10.11084-5.699702-13.760298s-8.599185-5.699702-13.760298-5.699702zm0 31.43c-6.627417 0-12-5.372583-12-12s5.372583-12 12-12 12 5.372583 12 12c.002654 3.18433-1.260457 6.239147-3.511181 8.491747-2.250724 2.252599-5.304488 3.518254-8.488819 3.518253z"
            fill="#000"
            opacity=".1"
          />
          <path
            d="m818 532.78h74.84v74.07c0 5.384776-4.365224 9.75-9.75 9.75h-55.32c-5.384776 0-9.75-4.365224-9.75-9.75v-74.07z"
            fill="currentColor"
          />
          <path d="m0 616.6h971.44v32.93h-971.44z" fill="#e0e0e0" />
          <path
            d="m638.97 396.26c-10.41-37.79-69.44-65.39-113.85-71.51 0-.28 0-.56 0-.84v-32.34h.14v-1.27c31.932675-11.879803 56.635727-37.773763 67-70.23 2.745174-4.964354 5.134512-10.117303 7.15-15.42 4.74-1.73 8.32-9.54 8.32-18.94.017496-3.214968-.43392-6.415305-1.34-9.5.582364-4.784764.879556-9.599937.89-14.42.035826-34.074139-14.394269-66.5618441-39.7-89.38.373386-1.9362271.501061-3.9118188.38-5.88-1-15.53-17.59-26.08-33.13-26.78 8.381551-9.5082617 10.534561-22.9947522 5.53-34.64-9.91 14.44-30.26 16-47.77 16.09 7.56-2.93 11-13.4 6.56-20.21-18.3 24.53-54 29.08-75.76 50.59-4.743304 4.7073109-8.718345 10.1299687-11.78 16.07-28.660552 22.8318804-45.34559 57.486838-45.32 94.13.009009 4.776289.299513 9.547895.87 14.29-.933225 3.124196-1.398277 6.369451-1.38 9.63 0 9.44 3.61 17.28 8.39 19 2.02302 5.320589 4.422424 10.490336 7.18 15.47 10.324309 32.271636 34.832695 58.054429 66.54 70v33.8.78c-44.58 6-104.08 33.83-114.4 71.92-8.91 32.9-18.42 69-23.16 90.24 40.57 28.51 125.69 45.24 179 45.24 50.69 0 133.53-15.11 172.91-41.06-4.16-23.36-13.97-61.09-23.27-94.83z"
            fill="url(#d)"
            opacity=".7"
          />
          <path
            d="m489.33 522.72c48.9 0 128.81-14.57 166.8-39.61-4.11-22.48-13.58-58.87-22.55-91.43-11.42-41.47-83.53-70.22-126.54-70.22h-41.45c-43.15 0-115.35 28.91-126.63 70.56-8.6 31.74-17.77 66.59-22.34 87.05 39.1 27.51 121.25 43.65 172.71 43.65z"
            fill="currentColor"
          />
          <circle cx="486.81" cy="165.5" fill="#120703" r="116.24" />
          <path
            d="m449.29 262.31h74.48v59.58c0 20.567084-16.672916 37.24-37.24 37.24s-37.24-16.672916-37.24-37.24z"
            fill="#000"
            opacity=".1"
          />
          <path
            d="m460 259.55h53.11c5.898401 0 10.68 4.781599 10.68 10.68v48.9c0 20.567084-16.672916 37.24-37.24 37.24s-37.24-16.672916-37.24-37.24v-48.9c-.000001-2.834246 1.126582-5.552262 3.131634-7.555438s4.724122-3.127216 7.558366-3.124562z"
            fill="#ffd0ba"
          />
          <path
            d="m449.43 290.43c24.003469 8.970352 50.426329 9.044853 74.48.21v-9.15h-74.48z"
            fill="#000"
            opacity=".1"
          />
          <circle cx="486.81" cy="188.67" fill="#ffd0ba" r="106.75" />
          <path
            d="m410.72 91.56s45 91.32 171.9 38.27l-29.5-46.32-52.4-18.8z"
            fill="#000"
            opacity=".1"
          />
          <g fill="#120703" transform="translate(380 10)">
            <path d="m30.72 80.22s45 91.32 171.9 38.27l-29.5-46.33-52.4-18.8z" />
            <path d="m30.37 74.05c3.034152-9.3906876 8.277647-17.9160824 15.29-24.86 21-20.76 55.42-25.14 73.08-48.81 4.24 6.57 1 16.67-6.33 19.49 16.89-.11 36.52-1.59 46.08-15.52 4.823976 11.2330932 2.745021 24.2401826-5.34 33.41 15 .68 31 10.86 32 25.83.65 10-5.61 19.43-13.81 25.15s-18.12 8.35-27.89 10.5c-28.54 6.3-131.73 32.64-113.08-25.19zm183.19 105.55h-2.84l-55.06 49c-53-24.85-92 0-92 0l-57.75-47-5.82.73c1.594724 58.333137 49.735185 104.575601 108.085275 103.823878 58.350089-.751723 105.28329-48.219018 105.374725-106.573878zm-106.92 67.82c-20.77 0-37.6-4.21-37.6-9.4s16.84-9.4 37.6-9.4 37.6 4.21 37.6 9.4-16.83 9.4-37.6 9.4z" />
          </g>
          <ellipse
            cx="380.01"
            cy="188.57"
            fill="#ffd0ba"
            rx="9.93"
            ry="18.62"
          />
          <ellipse
            cx="593.55"
            cy="188.57"
            fill="#ffd0ba"
            rx="9.93"
            ry="18.62"
          />
          <path
            d="m293.66 378.7h384.12c8.008129 0 14.5 6.491871 14.5 14.5v208.89c0 8.008129-6.491871 14.5-14.5 14.5h-384.12c-8.008129 0-14.5-6.491871-14.5-14.5v-208.89c0-8.008129 6.491871-14.5 14.5-14.5z"
            fill="url(#e)"
            opacity=".7"
          />
          <path
            d="m313.48 383.09h344.48c14.823323 0 26.84 12.016677 26.84 26.84v179.82c0 14.823323-12.016677 26.84-26.84 26.84h-344.48c-14.823323 0-26.84-12.016677-26.84-26.84v-179.82c0-14.823323 12.016677-26.84 26.84-26.84z"
            fill="#535461"
          />
          <circle cx="485.72" cy="499.85" fill="#fff" r="13.47" />
          <path d="m41.16 649.53h889.11v13.47h-889.11z" fill="#e0e0e0" />
          <path
            d="m41.16 649.53h889.11v4.49h-889.11z"
            fill="#000"
            opacity=".1"
          />
          <path
            d="m879.39 532.78v69.22c0 8.057835-6.532165 14.59-14.59 14.59h7.48c8.057835 0 14.59-6.532165 14.59-14.59v-69.22z"
            fill="#000"
            opacity=".1"
          />
          <path
            d="m832.78 477c-1.26.22-2.69.56-3.31 1.68-1 1.78.8 3.79 2.42 5 3 2.26 6.16 4.48 8.2 7.63s2.68 7.55.45 10.58c-2.91 3.95-9.85 4.79-10.52 9.65-.43 3.12 2.26 5.78 5 7.34 6.74 3.86 15.43 4.89 20.32 10.93 2-4.15 6.54-6.55 11-7.58s9.18-.95 13.75-1.64c1.47-.22 3.13-.67 3.79-2 1.28-2.59-2.36-5.7-1.11-8.3 1-2 3.76-1.83 5.81-2.62 3.34-1.29 4.71-5.59 3.77-9s-3.62-6.15-6.44-8.36c-7.58-6-16.7-9.46-25.93-12-4.29-1.17-9.33-3-13.78-3.19-4.25-.21-9.16 1.13-13.42 1.88z"
            fill="#eee"
          />
        </g>
      </g>
    </svg>
  );
}

SelfPortrait.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  role: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default SelfPortrait;
