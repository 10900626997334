import React from "react";

import css from "./MeInANutshell.module.scss";

export default function MeInANutshell() {
  return (
    <div className={css.summaryBox}>
      <p className={css.summaryText}>
        Seasoned frontend specialist; full-stack and devOps generalist.
        <br />
        With a keen eye for visual detail and interaction design,
        <br />I build responsive web apps that are
        <br />
        delightful, accessible, observable, and fast.
      </p>
    </div>
  );
}
