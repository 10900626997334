import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { HiItsMeHeader } from "../components/hiItsMeHeader";
import ThemeSwitch from "../components/ThemeSwitch";
import Experience from "../components/experience";
import MeInANutshell from "../components/MeInANutshell";
import Skills from "../components/skills";
import SocialStrip from "../components/socialStrip";
import Values from "../components/values";
import Education from "../components/education";
import "typeface-open-sans";

import css from "./index.module.scss";

function SoloProPage(props) {
  const [theme, setTheme] = useState("light");
  const toggleTheme = () => setTheme(theme === "light" ? "dark" : "light");

  useEffect(() => {
    const darkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    setTheme(darkTheme ? "dark" : "light");
  }, []);

  return (
    <>
      <Helmet
        title="Trevor Farlow: human software developer"
        meta={[
          { name: "author", content: "Trevor Farlow" },
          {
            name: "description",
            content: "Resume for Trevor Farlow, software developer.",
          },
          {
            name: "keywords",
            content:
              "Trevor Farlow, human software craftsmanship, software developer, front-end, full-stack, javascript",
          },
          { name: "language", content: "English" },
          { name: "viewport", content: "width=device-width" },
        ]}
      >
        <html lang="en" data-theme={theme} />
      </Helmet>
      <HiItsMeHeader />

      <ThemeSwitch onClick={toggleTheme} themeName={theme} />

      <div className={css.wrapper}>
        <MeInANutshell />
        <SocialStrip />

        {/* <div className={css.skillBox}> */}
        {/* <SkillPill color={sky}>JavaScript</SkillPill> */}
        {/* <SkillPill
        label="React"
        color="primary"
        clickable
        onDelete={() => {}}
        deleteIcon={<DoneIcon />}
      />
      <SkillPill label="Redux" />
      <SkillPill label="Redux" avatar={<Avatar>R</Avatar>} />
      <SkillPill label="Clojure" ariant="outlined" /> */}
        {/* </div> */}

        <Values />
        <Skills />
        <Experience />
        <Education />
      </div>
    </>
  );
}

export default SoloProPage;
