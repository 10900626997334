import React from "react";

import SelfPortrait from "./SelfPortrait";
import css from "./hiItsMeHeader.module.scss";

export const HiItsMeHeader = () => (
  <div className={css.wrapper}>
    <SelfPortrait
      alt="Cartoon man working on a computer"
      className={css.hiItsMe}
      role="img"
      title="I work best with a coffee and...a potted lettuce leaf?"
    />

    <h1 className={css.name}>Trevor Farlow</h1>
    <h2 className={css.selfGivenTitle}>human software craftsmanship</h2>
  </div>
);
