import React from "react";

import css from "./experience.module.scss";

const Experience = () => (
  <section className={css.experience}>
    <div className={css.experienceHeading}>
      <h2 className={css.experienceHeadingText}>Work</h2>
    </div>

    <div className={css.experienceContent}>
      <div className={css.job}>
        <div className={css.jobTitle}>Senior Software Engineer</div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            <a href="https://www.instinct.vet/">Instinct Science</a>
          </span>
          <span className={css.jobTenure}>2020 - Date.now()</span>
        </div>

        <p className={css.jobSummary}>
          Full-stack React development of veterinary electronic medical records
          in an early-stage startup. TypeScript and GraphQL against an Elixir
          backend running on AWS EKS (Kubernetes).
        </p>

        <div className={css.jobHighlights}>
          <ul>
            <li>
              Introduced full-stack observability using Datadog via
              OpenTelemetry while on the Platform team
            </li>
            <li>
              Cut build times in half and client bundle sizes by 25% by
              migrating frontend to Vite from webpack
            </li>
            <li>
              Optimized CI pipeline to save 60 hours of developer time every
              month
            </li>
            <li>
              Built a feature flag management frontend to give control to
              non-developer roles. Simultaneously pitched senior leadership for
              purchasing an off-the-shelf feature management system
              (LaunchDarkly) to give us superior tooling and allow our company
              to focus on its core competencies.
            </li>
            <li>
              Implemented a pattern for number and currency localization to
              support our international customer base
            </li>
            <li>
              Optimized image content on the app's landing page, reducing load
              times for our many low-end devices
            </li>
            <li>
              Laid out a design tokens approach for our first internal design
              system together with our UX designer
            </li>
          </ul>
        </div>
      </div>

      <div className={css.job}>
        <div className={css.jobTitle}>Open Source Maintainer</div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            <a href="https://github.com/actualbudget/">Actual Budget</a>
          </span>
          <span className={css.jobTenure}>Jan 2021 - Jan 2023</span>
        </div>

        <p className={css.jobSummary}>
          Part of the core maintainer team, handling code reviews and community
          decisions.
        </p>
        <ul>
          <li>
            Ported retired React Native code to a responsive web app to set the
            early direction for mobile support.
          </li>
          <li>Helped refine initial CI and Docker Hub image pipelines.</li>
          <li>Upgrade to React Router v6</li>
        </ul>
      </div>

      <div className={css.job}>
        <div className={css.jobTitle}>
          Software Engineer >> Senior >> Staff Engineer
        </div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            <a href="https://rally1.rallydev.com/">Rally Software</a> / CA
            Technologies /{" "}
            <a href="https://www.broadcom.com/products/software/value-stream-management/rally">
              Broadcom
            </a>
          </span>
          <span className={css.jobTenure}>Feb 2014 - Aug 2020</span>
        </div>

        <p className={css.jobSummary}>
          Frontend-weighted full-stack development in Rally, the $100M+ agile
          application lifecycle management product (think Jira, only way
          better), working in an event-driven microservices environment
          continuously delivered against a backdrop of more legacy frameworks
          than you can shake a stick at. Extensive test coverage and robust
          production observability. Helped support legacy Clojure/mongoDB
          microservices.
        </p>

        <div className={css.jobHighlights}>
          <dl>
            <dt>Team Contributions</dt>
            <dd>
              <ul>
                <li>
                  Mentor teammates in JavaScript, React, and Redux best
                  practices through code reviews, pair programming, training
                </li>
                <li>
                  Advise teammates in best practices for using our internal UI
                  design system
                </li>
                <li>
                  Organizer of an internal Front-end Developers Guild where best
                  practices are discussed and org-wide working agreements
                  decided
                </li>
                <li>
                  Volunteer “steward” for our front-end repository, tasked with
                  fostering developer productivity and a healthy codebase
                </li>
              </ul>
            </dd>
          </dl>

          <dl>
            <dt>Technical Contributions</dt>
            <dd>
              <ul>
                <li>
                  Converted our overhauled app's unit testing suite to Jest with
                  @testing-library (from Jasmine/enzyme), pruning 800 test
                  suites containing 9000 tests down to 130 suites containing the
                  1500 still-relevant tests
                </li>
                <li>
                  Drove a 90% reduction of client-side errors by developing a
                  triage tool using Node/GitHub Issues, boosting product
                  reliability and customer experience.
                </li>
                <li>
                  Implemented Bugsnag JavaScript error tracking after 3 years
                  maintaining the above tool
                </li>
                <li>
                  Revamped our front-end documentation content and build process
                  to catch up to the current state of our app
                </li>
                <li>
                  Eased adoption of a new design system (
                  <a href="https://mineral-ui.netlify.app/" target="_blank">
                    Mineral UI
                  </a>
                  ) by acting as a go-between for dev and UX
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>

      <div className={css.job}>
        <div className={css.jobTitle}>Volunteer Frontend Developer</div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            Colorado Citizen Software Engineers
          </span>
          <span className={css.jobTenure}>Spring 2020</span>
        </div>

        <p className={css.jobSummary}>
          Developed a COVID-19 triage hotline for uninsured Coloradans on a
          small team using TypeScript and Postgres, Twilio, AWS, and an
          electronic medical records system.
        </p>
      </div>

      <div className={css.job}>
        <div className={css.jobHeading}>
          <span className={css.jobTitle}>
            Data Analyst >> Agile Product Owner >> Software Developer
          </span>
          <span className={css.companyAndDates}>
            <span className={css.jobCompany}>Clearwater Analytics</span>
            <span className={css.jobTenure}>2007 - 2014</span>
          </span>
        </div>
        <div className={css.jobHighlights}>
          <ul>
            <li>
              Data transformation and queries, managed a team of data analysts,
              built a REST API with HTTP long-polling
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default Experience;
