import React from "react";

import githubIcon from "../images/github.svg";
import linkedInIcon from "../images/linkedin.svg";

import css from "./socialStrip.module.scss";

export const SocialStrip = () => (
  <div className={css.socialStrip}>
    <a className={css.social} href="https://github.com/trevdor">
      <img src={githubIcon} alt="GitHub Octocat" title="My GitHub profile" />
    </a>
    <a className={css.social} href="https://www.linkedin.com/in/trevorfarlow/">
      <img src={linkedInIcon} alt="LinkedIn logo" title="My LinkedIn profile" />
    </a>
  </div>
);

export default SocialStrip;
