import React from "react";

import css from "./skills.module.scss";

const Skills = () => (
  <section className={css.skills}>
    <div className={css.skillsHeading}>
      <h2 className={css.skillsHeadingText}>Skills</h2>
    </div>

    <div className={css.skillsContent}>
      <ul>
        <li>
          <dl>
            <dt>frontend</dt>
            <dd>
              JavaScript was my first language in 1998. I've worked in its
              modern incarnations the last decade; TypeScript the past three
              years. From Babel to bundling, CSS to CORS, React to responsive
              design, semantic HTML to service workers&mdash;the web is my
              platform of choice. And frontend is where I shine brightest.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>backend</dt>
            <dd>
              Currently working in Elixir with Phoenix Framework. Comfortable in
              Node and SQL (Postgres/Oracle/MS SQL Server/MySQL/mongoDB). It's
              been a minute, but I've worked in Java, Clojure, and PHP, even a
              dash of Ruby and Perl (talk to me about regular expressions :).
              I'll come up to speed in your backend. <br />
              I've a serviceable familiarity with the most common AWS services,
              including Lambda, S3, CloudFront, and more. I love a fluent REST
              API design, and find GraphQL powerful though overhead-heavy. I'm
              fascinated by scaled, distributed systems; both the capabilities
              and challenges that come with them.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>devOps</dt>
            <dd>
              Comfortable with AWS, Docker, and Kubernetes. Both professionally
              and for open source, I have helped maintain CI/CD pipelines using
              Github Actions, Jenkins, and CircleCI. I've instrumented Elixir
              and JavaScript apps with observability solutions like Heap
              Analytics, Sentry, and Datadog using OpenTelemetry as well as
              proprietary in-house distributed tracing systems similar to
              Jaeger.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>remote work</dt>
            <dd>
              After 5 years of remote work, I have a hard-won knowledge of the
              unique ingredients necessary for communication and collaboration
              in a distributed team. I have a reputation for transparent status
              updates, productive asynchronous work, and for fostering community
              within the team even from a distance.
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </section>
);

export default Skills;
