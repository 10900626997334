import React from "react";

import css from "./education.module.scss";

const Education = () => (
  <section className={css.education}>
    <div className={css.educationHeading}>
      <h2 className={css.educationHeadingText}>Education</h2>
    </div>

    <div className={css.educationContent}>
      <div className={css.job}>
        <div className={css.jobHeading}>
          <span className={css.jobTitle}>
            BS Molecular Biology, Computer Science minor
          </span>
          <span className={css.companyAndDates}>
            <span className={css.jobCompany}>Boise State University</span>
            <span className={css.jobTenure}>2003-2007</span>
          </span>
        </div>

        <div className={css.jobHighlights}>
          <ul>
            <li>
              Designed and developed a PHP/MySQL-powered webapp for presentation
              and analysis of genome data, including database design and
              webserver and version control (SVN) setup.
            </li>
            <li>Performed undergraduate research in organic chemistry.</li>
          </ul>
        </div>

        <div className={css.jobHighlights}>
          <dl>
            <dt>Computer Science Coursework</dt>
            <dd>
              <ul>
                <li>Database Theory (400)</li>
                <li>Cryptology I (300)</li>
                <li>Data Structures and Algorithms</li>
                <li>Discrete Math</li>
                <li>Calculus II</li>
                <li>Intro to Computer Science I &amp; II</li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </section>
);

export default Education;
