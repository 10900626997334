import React from "react";

import css from "./values.module.scss";

const Values = () => (
  <section className={css.values}>
    <div className={css.valuesHeading}>
      <h2 className={css.valuesHeadingText}>Values</h2>
    </div>

    <div className={css.valuesContent}>
      <ul>
        <li>
          <dl>
            <dt>human software</dt>
            <dd>
              Build with humility and empathy for the people whose daily lives
              are affected by your code. The best software is delightful,
              accessible, and fast. And often, the best solution isn't software
              at all.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>founder mentality</dt>
            <dd>
              Take pride of ownership in every project. Treat customers as{" "}
              <i>people</i>, not faceless units to be "acquired" and "retained".
              Bias toward action.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>people over process</dt>
            <dd>
              Team success trumps individual praise, and open communication
              beats knowledge hoarding. Together, devs, designers, and product
              experts arrive at creative ends none of them may have reached
              alone. Assume positive intent in others.
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>craftsmanship</dt>
            <dd>
              Take pride of ownership in every project. Simplicity,
              observability, confident test coverage. Success means shipping
              working features built out of roughly the right shapes
              <sup>
                <a href="https://web.archive.org/web/20181201212335/https://twitter.com/ryanflorence/status/702538809569726464">
                  1
                </a>
              </sup>
              , not some illusion of perfect code. Keep up with the fast-paced
              web ecosystem, but stay grounded in fundamentals.
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </section>
);

export default Values;
