import React from "react";
import PropTypes from "prop-types";

import clickAndKeyboard from "../utils/clickAndKeyboard";

import css from "./themeswitch.module.scss";

function Sun({ title }) {
  return (
    <svg
      aria-label={title}
      xmlns="http://www.w3.org/2000/svg"
      role="button"
      viewBox="0 0 16 16"
    >
      <title>{title}</title>
      <path
        d="M 7 1 L 7 4.050781 C 6.414063 4.136719 5.875 4.375 5.421875 4.710938 L 3.257813 2.550781 L 2.550781 3.257813 L 4.710938 5.421875 C 4.367188 5.875 4.132813 6.410156 4.046875 7 L 1 7 L 1 8 L 4.046875 8 C 4.132813 8.589844 4.367188 9.125 4.710938 9.578125 L 2.550781 11.742188 L 3.257813 12.449219 L 5.421875 10.285156 C 5.875 10.625 6.414063 10.863281 7 10.949219 L 7 14 L 8 14 L 8 10.949219 C 8.585938 10.863281 9.125 10.625 9.578125 10.285156 L 11.742188 12.449219 L 12.449219 11.742188 L 10.285156 9.578125 C 10.632813 9.125 10.867188 8.589844 10.953125 8 L 14 8 L 14 7 L 10.953125 7 C 10.867188 6.410156 10.632813 5.875 10.285156 5.421875 L 12.449219 3.257813 L 11.742188 2.550781 L 9.578125 4.710938 C 9.125 4.375 8.585938 4.136719 8 4.050781 L 8 1 Z M 7.5 5 C 8.886719 5 10 6.113281 10 7.5 C 10 8.886719 8.886719 10 7.5 10 C 6.113281 10 5 8.886719 5 7.5 C 5 6.113281 6.113281 5 7.5 5 Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

Sun.propTypes = {
  title: PropTypes.string.isRequired
};

function Moon({ title }) {
  return (
    <svg
      aria-label={title}
      xmlns="http://www.w3.org/2000/svg"
      role="button"
      viewBox="0 0 16 16"
    >
      <title>{title}</title>
      <path
        d="M 8.9296875 1 C 5.1274809 1 2 3.8964204 2 7.5 C 2 11.103479 5.126552 14 8.9296875 14 C 9.9106256 14 10.844133 13.805725 11.689453 13.460938 A 0.50005 0.50005 0 0 0 11.689453 12.535156 C 9.5830991 11.674984 8 9.7255827 8 7.5 C 8 5.2744173 9.5830991 3.3250157 11.689453 2.4648438 A 0.50005 0.50005 0 0 0 11.689453 1.5390625 C 10.843327 1.1943841 9.9096977 1 8.9296875 1 z M 8.9296875 2 C 9.348395 2 9.7117136 2.1513199 10.105469 2.2285156 C 8.2996855 3.3970316 7 5.2832399 7 7.5 C 7 9.7167601 8.2996854 11.602968 10.105469 12.771484 C 9.7117423 12.848673 9.3489244 13 8.9296875 13 C 5.630823 13 3 10.524521 3 7.5 C 3 4.4755796 5.6318941 2 8.9296875 2 z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
Moon.propTypes = {
  title: PropTypes.string.isRequired
};

function ThemeSwitch({ onClick, themeName }) {
  return (
    <div
      className={css.switch}
      {...clickAndKeyboard(onClick)}
      role="button"
      tabIndex={0}
    >
      {themeName === "light" ? (
        <Moon title="force dark mode" />
      ) : (
        <Sun title="force light mode" />
      )}
    </div>
  );
}

ThemeSwitch.propsTypes = {
  onClick: PropTypes.func.isRequired,
  themeName: PropTypes.string.isRequired
};

export default ThemeSwitch;
