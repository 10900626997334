export default function clickAndKeyboard(onClick) {
  return onClick
    ? {
        onClick,
        onKeyDown(e) {
          if (e.key === "Enter") {
            onClick(e, "keydown");
          }
        }
      }
    : {};
}
